import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'

import { parse as parseDate, format as formatDate } from 'date-fns'
import hljs from 'highlight.js'

import Layout from '../components/layout'
import '../../node_modules/highlight.js/styles/tomorrow-night-bright.css'

import { getPostType, postTypeClasses } from '../lib/util.js'

const postNavClass = 'bg-transparent hover:border-blue-600 text-gray-600 transition duration-75 font-medium hover:text-blue-600 py-2 px-4 text-sm border border-gray-600 rounded-sm';

const getNextPost = ({ next }) => {
  if (!next) return null;

  return (
    <Link
      className={postNavClass}
      to={`/blog/${next}`}
    >
      ←Next
    </Link>
  )
}

const getPrevPost = ({ prev }) => {
  if (!prev) return null;

  return (
    <Link
      className={postNavClass}
      to={`/blog/${prev}`}
    >
      Previous→
    </Link>
  )
}

class BlogPost extends Component {
  componentDidMount() {
    if (!window) return

    const blocks = window.document.querySelectorAll('pre code')
    blocks.forEach(hljs.highlightBlock)
  }

  render() {
    const { data, pageContext } = this.props
    const post = data.allGhostPost.edges[0].node
    const { title, html, published_at, slug } = post
    const currentPostURL = `https://kamal.io/blog/${slug}`

    const nextPost = getNextPost(pageContext)
    const prevPost = getPrevPost(pageContext)

    const date = formatDate(parseDate(post.published_at), 'MMMM MM YYYY')
    const postType = getPostType(post)
    const type = {
      name: postType,
      className: postTypeClasses[postType]
    }

    let { description } = post
    if (!description) {
      const d = post.plaintext
      description = d.length > 150 ? d.substr(0, 150) + '...' : d
    }

    return (
      <Layout>
        <Helmet title={title}>
          <meta name="description" content={description} />
          <meta property="og:site_name" content="Kamal Nasser" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={currentPostURL} />
          <meta property="article:published_time" content={published_at} />
          <meta property="article:modified_time" content={published_at} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:url" content={currentPostURL} />
          <meta name="twitter:label1" content="Written by" />
          <meta name="twitter:data1" content="Kamal Nasser" />
          <meta name="twitter:site" content="@kamaln7" />
          <meta name="twitter:creator" content="@kamaln7" />

          <link
            rel="alternate"
            type="application/rss+xml"
            title="Kamal Nasser"
            href="https://blog.kamal.io/rss/"
          />
        </Helmet>

        <div className="mb-12"></div>

        <main className="mb-16">
          <header className="mb-8">
            <h1 className="text-2xl leading-tight mb-6">
              { title }
            </h1>
            <p className="post-subheading text-blue-600 uppercase font-semibold text-sm mb-1">
              {date} <span className={type.className}>{'\u25AA'} {type.name}</span>
            </p>
          </header>

          <article className="leading-relaxed text-lg post-body" dangerouslySetInnerHTML={{ __html: html }}></article>
        </main>

        <aside className="flex items-center clearfix text-center mb-10" role="navigation">
          <div className="w-1/3">
            {nextPost}
          </div>
          <div className="w-1/3"></div>
          <div className="w-1/3">
            {prevPost}
          </div>
        </aside>

        <footer className="text-center text-gray-600">
          Thoughts? Comments?&nbsp;
          <a className="text-blue-600 hover:underline" href={`https://twitter.com/intent/tweet?text=%40kamaln7%20${encodeURIComponent(currentPostURL)}`}>
            <span role="img" aria-label="point right">👉</span> @ me on Twitter!
          </a>
        </footer>
      </Layout>
    )
  }
}

export default BlogPost

export const query = graphql`
  query($slug: String!) {
    allGhostPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          html
          published_at
          slug
          tags {
            name
          }

          plaintext
          meta_description
        }
      }
    }
  }
`
